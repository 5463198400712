import {
  Box,
  Container,
  Flex,
  Heading,
  List,
  Text,
  ListItem
} from '@chakra-ui/react';
import { SharedPageProps } from 'components/Layout';
import Link from 'next/link';
import { colors } from '../../constants/constants';
import Logo from '../../public/images/logo.svg';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function Footer({
  facilities,
  footerMenu,
  footerText
}: SharedPageProps): JSX.Element {

  const router = useRouter();
  const { pathname } = router;

  useEffect(() => {
    const scriptId = "weply-widget-script";
    const existingScript = document.getElementById(scriptId);

    if (existingScript) {
      existingScript.remove(); // Ta bort befintligt skript
    }

    const script = document.createElement("script");
    script.id = scriptId;
    script.src = "https://app.weply.chat/widget/fbd9228dc1f85a67233e4abf1759f167";
    script.async = true;

    document.body.appendChild(script); // Lägg till nytt skript

  }, [pathname]); // Körs varje gång URL:en ändras

  return (
    <Box
      bg={colors.secondary[900]}
      p={{ base: '2em 5%', md: '5em 5%', xl: '5em 0' }}>
      <Container maxW="7xl">
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          textAlign={{ base: 'center', md: 'left' }}>
          <Flex
            flex={{ base: 1, md: 30 }}
            m={{ base: 0, md: '0 3em 0 0' }}
            alignItems="flex-start"
            justifyContent={{ base: 'center', md: 'flex-start' }}>
            <Box color={colors.white}>
              <Box maxW="235px" mb={{ base: '3em' }}>
                <Logo fill="white" width="100%" />
              </Box>
              <Box fontSize="md" lineHeight={2}>
              {footerText?.text && (
                 <Container maxW="4xl" dangerouslySetInnerHTML={{ __html: footerText.text }} />
              )}
              </Box>
            </Box>
          </Flex>

          <Flex
            color={colors.white}
            flex={{ base: 1, md: 70 }}
            lineHeight="2"
            flexDir={{ base: 'column', md: 'row' }}>
            <Box flex="1" m={{ base: '2em 0', md: '0' }}>
              <Heading
                as="h4"
                size="sm"
                fontWeight="600"
                color={colors.secondary.footerGrey}>
                Våra förråd
              </Heading>

              {facilities && (
                <List>
                  {facilities.map((facility) => {
                    return (
                      <ListItem
                        key={facility.id}
                        style={{ transition: '.1s color ease-in-out' }}
                        _hover={{
                          color: colors.secondary.footerGrey,
                        }}>
                        <Link href={`/anlaggning/${facility.slug}`}>
                          {facility.name}
                        </Link>
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </Box>

            <Box flex="1" m={{ base: '.5em 0', md: '0' }}>
              <Heading
                as="h4"
                size="sm"
                fontWeight="600"
                color={colors.secondary.footerGrey}>
                Information om oss
              </Heading>

              <List>
                {footerMenu?.items?.map((x) => {
                  return (
                    <ListItem
                      key={x.path}
                      style={{ transition: '.1s color ease-in-out' }}
                      _hover={{
                        color: colors.secondary.footerGrey,
                      }}>
                      <Link href={`/${x.path}`}>
                        <a>{x.name}</a>
                      </Link>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}
